import { template as template_4c3fba4d1c9343c88211a51834df5f63 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_4c3fba4d1c9343c88211a51834df5f63(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
