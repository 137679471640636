import { template as template_b8901ae6b80c4b16801d7165772e1952 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_b8901ae6b80c4b16801d7165772e1952(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
